import { ElementType } from 'react';

import PageLayout, { Props, ServerSideProps as PageLayoutServerSideProps } from '@layouts/PageLayout';
import pick from 'utils/pick';

type AdditionalComponentProp = 'breadcrumbVariant' | 'breadcrumbs' | 'searchName' | 'withoutBreadcrumbBorder';

const withPageLayout = <T extends PageLayoutServerSideProps & Pick<Props, AdditionalComponentProp>>(
  Component: ElementType,
  pageLayoutProps?: Partial<Props>,
  additionalProps: (keyof T)[] = []
): ElementType => {
  const EnhancedComponent = (props: T): JSX.Element => {
    const { mainMenu, breadcrumbVariant, breadcrumbs, searchName, ...restProps } = props;
    return (
      <PageLayout
        mainMenu={mainMenu}
        breadcrumbVariant={breadcrumbVariant}
        breadcrumbs={breadcrumbs}
        searchName={searchName}
        {...pageLayoutProps}
      >
        <Component {...restProps} {...pick(props, additionalProps)} />
      </PageLayout>
    );
  };

  EnhancedComponent.withCompareFab = true;

  return EnhancedComponent;
};

export default withPageLayout;
